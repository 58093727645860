import React from 'react'

import { ChangePasswordContent } from '../../components/auth/ChangePasswordContent'
import { Layout } from '../../components/auth/Layout'
import { AccountType } from '../../requests'

const ChangePasswordPage = () => {
  return (
    <Layout accountType={AccountType.User}>
      <ChangePasswordContent accountType={AccountType.User} />
    </Layout>
  )
}

export default ChangePasswordPage
export const Head = () => <title>Datacapt - Change Password</title>
